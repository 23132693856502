/*!

=========================================================
* Argon Dashboard PRO Angular - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-angular
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Bootstrap functions
@import "bootstrap/functions";
@import "bootstrap/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

// Angular changes
@import "angular/dropdowns";
@import "angular/navbar-vertical";
@import "angular/progressbar";
@import "angular/plugin-ngx-chips";
@import "angular/ngx-toastr";
@import "angular/mobius1-selectr";
@import "angular/tooltips";
@import "angular/plugin-ngx-datatable";
@import "angular/navbar-vertical";
@import "angular/vector-map";
@import "~node_modules/moment-timezone-picker/assets/moment-timezone-picker.theme";
@import "~@ng-select/ng-select/themes/default.theme.css";



.badge-light-blue{
    background-color: #EEF7FE;
    color:#555684 ;
  }

.badge-purple-light{
    background-color: #22215B;
    color: #D3D3DE;
}

.touched-Pointer{
  cursor: pointer;
}

.button-btn{
  background-color: #ffff;
  color: #555684;
}

.colums-color{
  background-color: rgba(255, 255, 255, 0);
}

.btn-outline-purple {
    color: #22215B;
    background-color: transparent;
    background-image: none;
    border-color: #22215B;
    width: 150px ;
}

.btn-purple {
    color: #ffffff;
    background-color: #22215B;
    background-image: none;
    border-color: #22215B;
    width: 150px ;
}


.tab-background-color{
  background-color:rgba(255, 255, 255, 0.158);
  border-radius: 90px;
  margin-left: 40px;
  margin-right: 80px;
 
 
}

nav .nav-link:hover,.nav-item.active{
  background-color: #ffffff;

}

.nav-item.active{
  font-weight: bold;
}

.card-radius{
  border-radius: 15px;
}

.text-color-blue-custom{

  color:#7695F6;
}

.text-color-deep-blue-custom{
  color:#567DF4;
}

.text-color-enrollment-fee-custom{
  color:#595984 ;
}

.title-color-custom {
  color: #22215B;
  font-weight: bold;
}



  
  




