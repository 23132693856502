/* You can add global styles to this file, and also import other style files */

/* Default theme. ~960B */
@import '~@vime/core/themes/default.css';

/* Optional light theme (extends default). ~400B */
@import '~@vime/core/themes/light.css';

.form-control.ng-touched.ng-invalid{
  border: 2px solid #ff0000;
}

.mt-custom{
  margin-top: 30px;
  background-color: transparent;
  color: #ff8c00;
  border-color: #ff8c00;
  line-height: 1.5;
  border-radius: 0.25rem;
  height: calc( 1.5em + 1.25rem + 5px );
}

.mt-custom:hover{
  background-color: #ff8c00;
  color: #fff;
  border-color: #ff8c00;
}



.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #F49C1C;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:  #F49C1C transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.custom-feedback {
  margin-top: 10px;
}

.custom-feedback small {
  font-size: 12px;
  font-weight: 5px;
  
}

.form-control.is-invalid{
  
  border: 1px solid red  !important;
 
  
}
